
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import {
  IPriceTag,
  IPriceTagCreateRequest,
  IPriceTagRequest,
  IPriceTagUpdateRequest
} from "@/types/price_tag";
import { PriceTagRequest } from "@/models/price_tag";

@Component({})
export default class extends Vue {
  //price_tag
  @Action("price_tag/adminGet")
  public getPriceTag!: (price_tag_id: number) => void;

  @Action("price_tag/adminCreate")
  public create!: (params: IPriceTagCreateRequest) => boolean;

  @Action("price_tag/adminUpdate")
  public update!: (data: {
    price_tag_id: number;
    params: IPriceTagUpdateRequest;
  }) => boolean;

  @Action("price_tag/adminDelete")
  public deletePriceTag!: (price_tag_id: number) => boolean;

  @Getter("price_tag/single")
  public price_tag!: IPriceTag;

  @Mutation("price_tag/clear")
  public clear!: () => void;

  //パラメータ定義
  public params: IPriceTagRequest = new PriceTagRequest();

  //変数定義
  public price_tag_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;

  //ルール設定
  public rules = {
    name: [(v: string) => !!v || "諸経費名は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.price_tag_id = Number(this.$route.params.price_tag_id);
    this.clear();

    // 編集時
    if (this.price_tag_id) {
      await this.getPriceTag(this.price_tag_id);

      this.setDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromPriceTag(this.price_tag);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.price_tag_id) {
      result = await this.update({
        price_tag_id: this.price_tag_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.submit_dialog = false;
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deletePriceTag(this.price_tag_id)) {
      this.$router.push(`/admin/price_tag/list`);
    }
  }
}
